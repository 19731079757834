import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typescript"
    }}>{`TypeScript`}</h1>
    <p>{`You can use TypeScript to get full type safety in your components. `}<inlineCode parentName="p">{`use-axios-client`}</inlineCode>{` ships type definitions, so there's no need to install any `}<inlineCode parentName="p">{`@types`}</inlineCode>{` packages.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <ol>
      <li parentName="ol">{`Define your data response shape:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`interface Data {
  name: string;
  age: number;
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Pass it to a hook:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const { data, error, loading } = useAxios<Data>({
  url: 'https://example/api',
});
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Get type feedback as you develop:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`return <div>{data.location}</div>;
// Property 'location' does not exist on type '{ name: string; age: number; }'
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      